<template>
    <div class="choose-mentee">
        <b-form inline v-if="mentees.length > 0">
            <b-form-select :options="mentees" v-model="mentee" class="mr-2">
                <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Choose Mentee --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form>
    </div>
</template>

<script>
    import Providers from "../../providers";

    export default {
        name: "MenteeSelector",
        data() {
            return {
                mentees: [],
                loading: false,
                mentee: null,
            }
        },
        watch: {
            mentee() {
                this.$emit('mentee-selected', this.mentee);
            }
        },
        methods: {
            loadMentees() {
                this.loading = true;
                Providers.mentees.mentees().then((resp) => {
                    this.mentees = resp.body.map(m => ({
                        text: m.name,
                        value: m.userId
                    }));
                }).catch((resp) => {
                    this.$httpError('Failed to load mentees', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
        },
        created() {
            this.loadMentees();
        }
    }
</script>

<style scoped>

</style>