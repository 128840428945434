<template>
    <div class="create-review">
        <b-modal ref="modal" :title="title" header-bg-variant="primary" header-text-variant="light" size="lg"
                 ok-title="Create" @ok="create" :ok-disabled="loading" :cancel-disabled="loading" no-close-on-backdrop no-close-on-esc>
            <div class="form" v-if="review">
                <b-form-group label="Date">
                    <b-form-datepicker v-model="review.date" />
                </b-form-group>
                <b-form-group label="View Portfolio">
                    <LoadingButton variant="info" :disabled="!review.date" :loading="portfolioLoading" @click="downloadPortfolio">Download Portfolio</LoadingButton>
                </b-form-group>
                <b-form-checkbox v-model="review.metRequirements" switch>
                    This person has met their requirements
                </b-form-checkbox>
                <b-form-group label="Comments" class="mt-2">
                    <b-textarea rows="6" v-model="review.comments"></b-textarea>
                </b-form-group>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import Providers from '../../providers';
    import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';

    const DEFAULT_REVIEW = {
        type: 'Annual',
        date: null,
        comments: '',
        metRequirements: false,
    }

    export default {
        name: "CreateReviewModal",
        components: {LoadingButton},
        data() {
            return {
                loading: false,
                review: null,
                mentee: null,
                portfolioLoading: false,
            }
        },
        computed: {
            title() {
                if (this.review) {
                    return 'Create ' + this.review.type + ' Review'
                }
                return '';
            }
        },
        methods: {
            show(user, type) {
                this.mentee = user;
                this.review = {...DEFAULT_REVIEW};
                this.review.type = type;
                this.$refs.modal.show();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.mentor.reviews.createReview(this.mentee, this.review).then(() => {
                    this.$success('Review Added', 'The review has been added successfully');
                    this.$emit('review-added');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to add review', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            downloadPortfolio() {
                this.portfolioLoading = true;
                Providers.mentor.reviews.downloadPortfolio(this.mentee, this.review.date).then((download) => {
                    download();
                }).catch((resp) => {
                    this.$httpError('Failed to create portfolio', resp);
                }).finally(() => {
                    this.portfolioLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>