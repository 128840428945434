<template>
    <div class="reviews">
        <b-row>
            <b-col md="6">
                <MenteeSelector @mentee-selected="openMentee" />
            </b-col>
        </b-row>
        <b-row v-if="mentee" class="mt-4">
            <b-col cols="12" class="text-right">
                <div class="actions">
                    <b-button variant="info" @click="createReview('Interim')">+ Interim Review</b-button>
                    <b-button variant="primary" @click="createReview('Annual')">+ Annual Review</b-button>
                </div>
            </b-col>
            <b-col cols="12" class="mt-2">
                <PreviousReviews ref="previousReviews" :user-id="mentee" />
            </b-col>
        </b-row>
        <CreateReviewModal ref="createModal" @review-added="reviewAdded" />
    </div>
</template>

<script>
    import MenteeSelector from "../../components/mentor/MenteeSelector";
    import PreviousReviews from "../../components/profile/PreviousReviews";
    import CreateReviewModal from "../../components/mentor/CreateReviewModal";
    export default {
        name: "Reviews",
        components: {CreateReviewModal, PreviousReviews, MenteeSelector},
        data() {
            return {
                mentee: null,
            }
        },
        methods: {
            openMentee(userId) {
                this.mentee = userId;
            },
            createReview(type) {
                this.$refs.createModal.show(this.mentee, type);
            },
            reviewAdded() {
                this.$refs.previousReviews.loadReviews();
            }
        }
    }
</script>

<style scoped>
div.actions > * {
    margin-right: 5px;
}
</style>